import { AccountType, Provider } from "@/lib/constants"

export function formatBytes(bytes: number, decimals = 0, sizeType: "accurate" | "normal" = "normal") {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"]
  if (bytes === 0) return "0 Byte"
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${
    sizeType === "accurate" ? accurateSizes[i] ?? "Bytest" : sizes[i] ?? "Bytes"
  }`
}

export function formatVideoDuration(duration: number) {
  if (!duration) {
    return "00:00"
  }

  const hours = Math.floor(duration / 3600)
  let minutes = Math.floor((duration % 3600) / 60)
  const seconds = Math.round(duration % 60)
  if (hours >= 1) {
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
  }
  minutes = Math.floor(duration / 60)
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`
}

export function formatNumber(number: number | string, options: Intl.NumberFormatOptions = {}) {
  return new Intl.NumberFormat("en-US", {
    style: options.style ?? "decimal",
    notation: options.notation ?? "standard",
    minimumFractionDigits: options.minimumFractionDigits ?? 0,
    maximumFractionDigits: options.maximumFractionDigits ?? 2,
    ...options,
  }).format(Number(number))
}

export const formatProvider = (provider: string) => {
  if (provider.includes("line")) {
    return "Line"
  }
  if (provider.includes(Provider.Google)) {
    return "Google"
  }
  if (provider.includes("facebook")) {
    return "Facebook"
  }
  if (provider.includes("passwordless")) {
    return "Email"
  }
  return provider
}

export const formatDateToISO = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset() * 60 * 1000

  const adjustedDate = new Date(date.getTime() - timezoneOffset)

  const adjustedIsoString = adjustedDate.toISOString().split(".")[0]

  return adjustedIsoString
}

export function formatPhoneNumber(phoneNumber: string) {
  if (phoneNumber.length === 9) {
    return phoneNumber.slice(0, 2) + "-" + phoneNumber.slice(2, 5) + "-" + phoneNumber.slice(5)
  }
  const formattedNumber = phoneNumber.replace(/(\d{3})(?=(\d{3}))/g, "$1-")

  if (phoneNumber.length > 6) {
    return formattedNumber.slice(0, 4) + formattedNumber.slice(4)
  }

  return formattedNumber
}

export function formatNationalId(input: string) {
  if (input === "") return ""

  const str = input.toString()

  const groupSizes = [1, 4, 5, 2, 1]

  let currentPosition = 0
  const result = []

  for (let size of groupSizes) {
    result.push(str.slice(currentPosition, currentPosition + size))
    currentPosition += size
  }

  return result.join("-")
}

export function formatBankAccount(bankCode: string, bankNumber: string) {
  const defaultFormat = [3, 1, 5, 1]

  const bankCodeFormat: { [key: string]: number[] } = {
    "002": defaultFormat,
    "004": defaultFormat,
    "006": defaultFormat,
    "011": defaultFormat,
    "017": defaultFormat,
    "022": defaultFormat,
    "024": defaultFormat,
    "025": defaultFormat,
    "030": defaultFormat,
    "067": defaultFormat,
    "069": defaultFormat,
  }

  const format = bankCodeFormat[bankCode] ?? defaultFormat

  let start = 0
  const result = []

  for (const i of format) {
    result.push(bankNumber.slice(start, start + i))
    start += i
  }

  return result.join("-") + bankNumber.slice(10, bankNumber.length)
}

export function formatAccountNumber(bankCode: string, accountNumber: string, type: string) {
  return type === AccountType.BANK_ACCOUNT
    ? formatBankAccount(bankCode ?? "", accountNumber)
    : type === AccountType.PROMPTPAY_NATIONAL_ID
    ? formatNationalId(accountNumber)
    : formatPhoneNumber(accountNumber)
}

export function formatStringToNumber(value: string): string {
  return value.replace(/[^0-9]/g, "")
}
